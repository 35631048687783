import React from "react"
import Layout from "../components/Layout" 
import { graphql } from "gatsby"

const WorkPage = ({ data }) => {

  return (
    <>
      <Layout>
        <div className="slide">
          <div className="project-wrapper">
            <div className="project">
              <div className="img"></div>
              <div className="title"></div>
              <a className="button"></a>
            </div>
          </div>

        </div>
      </Layout>
    </>
  )
}

export default WorkPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
